import React, { useState } from "react"
import { Container, Row, Col, Image, Button } from "react-bootstrap"
import { FaYoutube, FaInstagram, FaDiscord, FaTwitter } from 'react-icons/fa';
import { CgClose } from 'react-icons/cg';
import { HiPlay } from 'react-icons/hi'
import BotLogo from "../../../../images/BOTLogoWithText.png"

const styles = require("./landing-page.module.scss")

type LandingPageBodyProps = {}

/**
 * Landing page for BOT.
 */
const LandingPageBody: React.FC<LandingPageBodyProps> = () => {
  const [shouldShowTrailer, setShouldShowTrailer] = useState(false);

  return (
    <div className={styles.welcomePage}>
      {shouldShowTrailer && (
        <Container className={styles.welcomeTrailer} fluid>
          <CgClose
            className={styles.welcomeTimesIcon}
            color={"white"}
            size={45}
            onClick={() => setShouldShowTrailer(false)}
          />
          <Row className={styles.trailerContainer}>
            <Col className="d-flex align-items-center justify-content-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/GHT_HGKF6v4?autoplay=1"
                title="YouTube video player"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              />
            </Col>
          </Row>
        </Container>
      )}

      <Container className={styles.welcomeButtons} fluid>
        <div className={`d-flex flex-column justify-content-center ${styles.welcomePageContent}`}>
          <Row>
            <Col className="d-flex justify-content-center">
              <Image className={styles.welcomeBotLogo} src={BotLogo} />
            </Col>
          </Row>
          <Row>
            <Col className={`d-flex justify-content-center ${styles.buttonsRow}`}>
              <a href="/home">
                <Button
                  variant="primary"
                  className={styles.welcomeEnterButton}
                >
                  ENTER
                </Button>
              </a>

              <Button
                variant="primary"
                className={styles.welcomeTrailerButton}
                onClick={() => setShouldShowTrailer(true)}
              >
                <HiPlay size={35} /> TRAILER
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className={`d-flex justify-content-center ${styles.alternateWelcomeSocialIcons}`}>
              <a href="https://www.youtube.com/channel/UCGuQMgV_hLcxZPSjN7_WUxQ/featured" target="_blank">
                <FaYoutube className={`${styles.welcomeSocialIcon} ${styles.lastNavSocialIcon}`} color={"white"} size={30}/>
              </a>
              <a href="https://www.instagram.com/bionicoutliertribe/" target="_blank">
                <FaInstagram className={`${styles.welcomeSocialIcon} ${styles.lastNavSocialIcon}`} color={"white"} size={30}/>
              </a>
              <a href="https://www.discord.gg/7eTqwxn6nW" target="_blank">
                <FaDiscord className={styles.welcomeSocialIcon} color={"white"} size={30} />
              </a>
              <a href="https://twitter.com/botnfts" target="_blank">
                <FaTwitter className={`${styles.welcomeSocialIcon} ${styles.lastNavSocialIcon}`} color={"white"} size={30}/>
              </a>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default LandingPageBody
