import React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LandingPageBody from "../components/pages/lading-page/body"

const IndexPage: React.FC<PageProps> = pageProps => {
  return (
    <Layout
      pageProps={pageProps}
      accountAddress={null}
      contract={null}
      getAndSetAccount={null}
      shouldShowNavbar={false}
      shouldShowFooter={false}
    >
      <SEO title="Welcome" />
      <LandingPageBody />
    </Layout>
  )
}

export default IndexPage
